import React, { Component } from "react";
import Fade from "react-reveal";
import home from "../../assets/images/home/home-2.jpg";

import AmazonAndPillpack from "./AmazonAndPillpack";
import Criteria from "./Criteria";
import Paperbag from "./Paperbag";
import CreateValue from "./CreateValue";
import CustomTrailer from "./CustomTrailer";

import "./home.scss";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeData: this.props.homeData,
    };
  }
  render() {
    return (
      <div className="aims-home-container">
        <div className="aims-home-container-header">
          <img src={home}></img>
          <div className="banner-container">
            <Fade bottom>
              <h1 className="aims-responsive-headline">Vision and Values</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3 style={{ color: "white" }}>
                We aim to be a global, strategic partner for organizations
                pursuing “Organization Excellence”
              </h3>
            </Fade>
            <Fade>
              <div
                className="aims-values"
                style={{
                  display: "flex",
                  "justify-content": "space-between",
                  marginTop: "100px",
                }}
              >
                <p>Client orientation</p>
                <p>Committed support</p>
                <p>Long-term relationships</p>
                <p>Sustainability and profitability</p>
              </div>
            </Fade>
          </div>
        </div>

        <AmazonAndPillpack />
        <Criteria />
        <Paperbag />
        <CreateValue />
        <CustomTrailer />
      </div>
    );
  }
}

export default Home;
