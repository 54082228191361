import React, { Component } from "react";
import ImageViews from "../ImageViews";
import "./Client.scss";

import img1 from "../../assets/images/clients/1.png";
import img2 from "../../assets/images/clients/2.png";
import img3 from "../../assets/images/clients/3.png";
import img4 from "../../assets/images/clients/4.png";
import img5 from "../../assets/images/clients/5.png";
import img6 from "../../assets/images/clients/6.png";
import img7 from "../../assets/images/clients/7.png";
import img8 from "../../assets/images/clients/8.png";
import img9 from "../../assets/images/clients/9.png";
import img10 from "../../assets/images/clients/10.png";
import img11 from "../../assets/images/clients/11.png";
import img12 from "../../assets/images/clients/12.png";
import img13 from "../../assets/images/clients/13.png";
import img14 from "../../assets/images/clients/14.png";
import img15 from "../../assets/images/clients/15.png";
import img16 from "../../assets/images/clients/16.png";
import img17 from "../../assets/images/clients/17.png";
import img18 from "../../assets/images/clients/18.png";
import img19 from "../../assets/images/clients/19.png";
import img20 from "../../assets/images/clients/20.png";
import img21 from "../../assets/images/clients/21.png";
import img22 from "../../assets/images/clients/22.png";
import img23 from "../../assets/images/clients/23.png";
import img24 from "../../assets/images/clients/24.png";
import img25 from "../../assets/images/clients/25.png";
import img26 from "../../assets/images/clients/26.png";
import img27 from "../../assets/images/clients/27.png";
import img28 from "../../assets/images/clients/28.png";
import img29 from "../../assets/images/clients/29.png";
import img30 from "../../assets/images/clients/30.png";
import img31 from "../../assets/images/clients/31.png";
import img32 from "../../assets/images/clients/32.png";
import img33 from "../../assets/images/clients/33.png";
import img34 from "../../assets/images/clients/34.png";
import img35 from "../../assets/images/clients/35.png";
import img36 from "../../assets/images/clients/36.png";
import img37 from "../../assets/images/clients/37.png";
import img38 from "../../assets/images/clients/38.png";
import img39 from "../../assets/images/clients/39.png";
import img40 from "../../assets/images/clients/40.png";
import img41 from "../../assets/images/clients/41.png";
import img42 from "../../assets/images/clients/42.png";
import img43 from "../../assets/images/clients/43.png";
import img44 from "../../assets/images/clients/44.png";
import img45 from "../../assets/images/clients/45.png";
import img46 from "../../assets/images/clients/46.png";
import img47 from "../../assets/images/clients/47.png";
import img48 from "../../assets/images/clients/48.png";
import img49 from "../../assets/images/clients/49.png";
import img50 from "../../assets/images/clients/50.png";
import background from "../../assets/images/clients/back.jpg";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  createImageContainers() {
    return [
      {
        img: img1,
        title: "Windsor Regional Hospital",
      },
      {
        img: img2,
        title: "The Original Cakerie",
      },
      {
        img: img3,
        title: "Craft Foods",
      },
      {
        img: img4,
        title: "Cadbury",
      },
      {
        img: img5,
        title: "Heinz Kraft",
      },
      {
        img: img6,
        title: "Halls",
      },
      {
        img: img7,
        title: "",
      },
      {
        img: img8,
        title: "",
      },
      {
        img: img9,
        title: "Oreo",
      },
      {
        img: img10,
        title: "",
      },
      {
        img: img11,
        title: "IMT",
      },
      {
        img: img12,
        title: "",
      },
      {
        img: img13,
        title: "",
      },
      {
        img: img14,
        title: "",
      },
      {
        img: img15,
        title: "BMW",
      },
      {
        img: img16,
        title: "Mini",
      },
      {
        img: img17,
        title: "",
      },
      {
        img: img18,
        title: "",
      },
      {
        img: img19,
        title: "",
      },
      {
        img: img20,
        title: "",
      },
      {
        img: img21,
        title: "",
      },
      {
        img: img22,
        title: "",
      },
      {
        img: img23,
        title: "",
      },
      {
        img: img24,
        title: "",
      },
      {
        img: img25,
        title: "",
      },
      {
        img: img26,
        title: "",
      },
      {
        img: img27,
        title: "",
      },
      {
        img: img28,
        title: "",
      },
      {
        img: img29,
        title: "",
      },
      {
        img: img30,
        title: "",
      },
      {
        img: img31,
        title: "",
      },
      {
        img: img32,
        title: "",
      },
      {
        img: img33,
        title: "",
      },
      {
        img: img34,
        title: "",
      },
      {
        img: img35,
        title: "",
      },
      {
        img: img36,
        title: "",
      },
      {
        img: img37,
        title: "",
      },
      {
        img: img38,
        title: "",
      },
      {
        img: img39,
        title: "",
      },
      {
        img: img40,
        title: "",
      },
      {
        img: img41,
        title: "",
      },
      {
        img: img42,
        title: "",
      },
      {
        img: img43,
        title: "",
      },
      {
        img: img44,
        title: "",
      },
      {
        img: img45,
        title: "",
      },
      {
        img: img46,
        title: "",
      },
      {
        img: img47,
        title: "",
      },
      {
        img: img48,
        title: "",
      },
      {
        img: img49,
        title: "",
      },
      {
        img: img50,
        title: "",
      },
    ];
  }

  render() {
    var imageList = this.createImageContainers();
    return (
      <>
        <div className="client-header-container">
          <img src={background}></img>
          <h1 className="client-header">Our Clients</h1>
        </div>
        <div className="client-container-images">
          {/* <ImageViews imageList={imageList} /> */}
          <div class="aims-customer-container">
            {imageList.map((item, index) => {
              return (
                <div key={index} className="customer-item">
                  <img src={item.img}></img>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

export default Clients;
