import React, { Component } from "react";

class CustomTrailer extends Component {
  render() {
    return (
      <div
        id="customtrailer"
        style={{ padding: "30px", backgroundColor: "#3b5999" }}
      >
        <div style={{ padding: "30px" }}>
          <section id="custom-trailers">
            <h2 style={{ color: "white" }}>
              Custom Trailer Axle Design & Manufacturing
            </h2>
            <div>
              <h5>OEM – Integrated Trailer Axle Manufacturer</h5>
              <ul style={{ "padding-left": "30px", listStyleType: "disc" }}>
                <li>70 Million Annual sales </li>
                <li>Low volume High Variety segment</li>
                <li>3 plants with 190 team members</li>
              </ul>
              <h5 style={{ color: "white" }}>Business Challenge</h5>
              Engineering resources were perpetually working over time{" "}
              <span style={{ color: "rgb(255,17,35)" }}>($23,000/month)</span>
              <ul style={{ "padding-left": "30px", listStyleType: "disc" }}>
                <li>Missing launch dates</li>
                <li>Too many design iteration loops</li>
                <li>
                  Field warranty and quality issues (1.6 Million, annual costs)
                </li>
              </ul>
              <h5 style={{ color: "white" }}>Action Taken</h5>
              <ul style={{ "padding-left": "30px", listStyleType: "number" }}>
                <li>Implemented visual board for contract review</li>
                <li>
                  Work flow generated by web based customer requests improved by
                  creating
                  <ul style={{ listStyleType: "disc" }}>
                    <li>Engineering Standards</li>
                    <li>Made database searchable</li>
                    <li>Constraints were added to the web applications</li>
                  </ul>
                </li>
                <li>Co located cross functional team members</li>
              </ul>
              <h5 style={{ color: "white" }}>Business Results</h5>
              <ul style={{ "padding-left": "30px", listStyleType: "disc" }}>
                <li>Engineering capacity increased by 46%</li>
                <li>Overtime completely eliminated</li>
                <li>
                  New axles designed and launched in 8 months as compared to 22
                  months
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CustomTrailer;
