import React, { Component } from "react";
import image1 from "../../assets/images/amazon-preview.png";
import image2 from "../../assets/images/amaz.png";
import image3 from "../../assets/images/ppp.png";

class AmazonAndPillpack extends Component {
  render() {
    return (
      <div
        id="amaonandpillpack"
        className="amazon-pillpack"
        style={{ padding: "30px 100px", backgroundColor: "white" }}
      >
        <div style={{ padding: "30px" }}>
          <div
            style={{
              display: "flex",
              "justify-content": "space-around",
              "align-items": "center",
            }}
          >
            <div className="amazon-logo">
              <img src={image1}></img>
            </div>
            <div className="amazon-logo">
              <img src={image2}></img>
            </div>
            <div className="amazon-logo">
              <img src={image3}></img>
            </div>
          </div>

          <div>
            <div></div>
            <div>
              <h4>Thursday 28th June 2018</h4>
              <p>
                The online retail giant outbid rival Walmart Inc. to win
                PillPack, Bloomberg reported
                <span style={{ color: "#0bb153" }}>($1B -/+)</span>
              </p>
              <p>
                <span style={{ color: "#ff1123" }}>$10B </span>Market Cap drop
                for Walgreen, CVS and Rite Aid, on the same day
              </p>
            </div>
          </div>

          <div style={{ color: "black" }}>
            <h2 style={{ color: "#00b0f0" }}>AIMS Inc.</h2>
            <h4>Build Capability at PillPack</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                "margin-left": "100px",
              }}
            >
              <ul
                className="amazon-pillpack-ul"
                style={{ listStyleType: "square" }}
              >
                <li>
                  <span style={{ color: "#00b0f0" }}>Hoshin Planning</span>{" "}
                  thinking for the leadership team
                  <ul>
                    <li>
                      {" "}
                      - Define the X – Matrix that captures all the critical
                      objectives for PillPack
                    </li>
                  </ul>
                </li>
                <li>
                  Build Continuous Improvement capability for a selected 8
                  member team
                  <ul>
                    <li>
                      {" "}
                      - 3 weeks of mentoring, customized teaching and
                      implementation support on the philosophy and tools of
                      continuous improvement based on Dr. Deming’s framework of{" "}
                      <span style={{ color: "#ff0000" }}>
                        “Profound Knowledge”
                      </span>
                      <ol
                        className="amazon-pillpack-ol"
                        style={{ listStyleType: "lower-alpha" }}
                      >
                        <li>Systems Thinking</li>
                        <li>Understanding of Variation</li>
                        <li>Theory of Knowledge</li>
                        <li>Team Dynamics</li>
                      </ol>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <h2 style={{ color: "#00b0f0" }}>PillPack.com</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ul className="" style={{ listStyleType: "circle" }}>
                <li>
                  PillPack personalizes shipments for people taking multiple
                  medications and streamlines the refill and co-pay process with
                  behind-the-scenes software that automates many basic pharmacy
                  tasks.
                </li>
                <li>
                  PillPack is a niche player with more than 50,000 customers. It
                  also has pharmacy licenses in all 50 states
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AmazonAndPillpack;
