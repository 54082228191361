import React, { Component } from "react";
import img from "../../assets/images/value.png";

class CreateValue extends Component {
  render() {
    return (
      <div id="createvalue">
        <div style={{ padding: "30px", backgroundColor: "white" }}>
          <section id="create-value">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h2 style={{ color: "black" }}>
                How does AIMS Inc create Value?
              </h2>
            </div>
            <div>
              <ul
                className="create-value-ul"
                style={{ "padding-left": "30px" }}
              >
                <li>
                  Create-Capture-Deliver Value in conceptualizing, designing and
                  launching new service or products
                </li>
                <li>
                  For existing service or product offering, close the fiscal gap
                  between Sales Decay and Revenue/Profit curve
                </li>
              </ul>
            </div>
            <div>
              <h4>
                AIMS Inc can support and deliver results during every phase of
                the Automotive product launch process
              </h4>
              <div
                style={{
                  "marging-top": "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={img} style={{ width: "60%" }}></img>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CreateValue;
