import React, {Component} from "react";
import { Fade, Slide } from "react-reveal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import servicesImage from "../../assets/images/contactus/contactus.jpg"

import "./Contact-us.scss";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <>
            <div className="contactus-header-image">
                <img src={servicesImage} />
                <h1 className="contact-us-header">Contact Us</h1>
            </div>
            <div className="contact-us-container">
                <div className="contact-us-address-container">
                    <div className="contact-us-address-container-child">
                        <i class="fa-solid fa-house fa-2xl"></i><br />
                        77 South Carriage Road London,<br />
                        Ontario N6H 5M2<br />
                    </div>
                    <div className="contact-us-address-container-child">
                        <i class="fa-solid fa-phone fa-2xl"></i><br />
                        519-471-3483 <br />
                        fax: 519-471-7990<br />
                        cell:519-857-5790<br />
                    </div>
                    <div className="contact-us-address-container-child">
                        <i class="fa-solid fa-envelope fa-2xl"></i>
                        <p><a href="mailto:Vaidesh@aimsinc.ca">Vaidesh@aimsinc.ca</a></p>
                    </div>                  
                </div>
            </div>
            <div className="contact-us-map-container">
                <iframe style={{height:"100%", width:"100%"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2918.214243034944!2d-81.3324472!3d42.9948231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef0046eda10ad%3A0xaa1d22d8b26504a6!2sS%20Carriage%20Rd%2C%20London%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sin!4v1649526709292!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            </>
         );
    }
}
 
export default ContactUs;