import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import $ from "jquery";

import Home from "../Components/Home/Home";
import Services from "../Components/Services/Services";
import CaseStudies from "../Components/Case-Studies/CaseStudies";
import Clients from "../Components/Clients/Clients";
import AboutUs from "../Components/AboutUs/AboutUs";
import ContactUs from "../Components/ContactUs/ContactUs";
import Logo from "../assets/logo.png";

import "./nav-style.scss";

class SiteRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
    };
  }

  getServicesDataData() {
    $.ajax({
      url: "./SiteData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ data: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getServicesDataData();
  }

  render() {
    const homeData = this.state.data.home;
    return (
      <Router>
        <div>
          <nav className="aims-nav">
            <div className="aims-nav-logo">
              <Link to="/">
                <img src={Logo}></img>
              </Link>
            </div>
            <div className="aims-nav-menu">
              <ul className="aims-nav-menu-ul">
                <li>
                  <NavLink to="/" activeClassName="aims-active-link">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/services" activeClassName="aims-active-link">
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/case-studies">Case-Studies</NavLink>
                </li>
                <li>
                  <NavLink to="/our-clients" activeClassName="aims-active-link">
                    Our Clients
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/about" activeClassName="aims-active-link">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us" activeClassName="aims-active-link">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>

          {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
          <div className="aims-nav-router-container">
            <Switch>
              <Route path="/about">
                <AboutUs />
              </Route>
              <Route path="/services">
                <Services />
              </Route>
              <Route path="/our-clients">
                <Clients />
              </Route>
              <Route path="/contact-us">
                <ContactUs />
              </Route>
              <Route path="/case-studies">
                <CaseStudies />
              </Route>
              <Route path="/">
                <Home homeData={homeData} />
              </Route>
            </Switch>
          </div>
          <div
            className="aims-footer"
            style={{
              color: "black",
              padding: "0px 100px",
              "align-items": "center",
              display: "flex",
              "justify-content": "space-between",
            }}
          >
            <p>Think Big, Start Small, Move Fast</p>
            <p>
              Aims Inc : Analysis & Implementation of Management Systems inc
            </p>
          </div>
        </div>
      </Router>
    );
  }
}

export default SiteRouter;
