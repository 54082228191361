import React, { Component } from "react";

class Paperbag extends Component {
  render() {
    return (
      <div
        id="paperbag"
        style={{ padding: "30px 100px", backgroundColor: "#191919" }}
      >
        <div style={{ padding: "30px" }}>
          <section id="paperbag">
            <h2 style={{ color: "white" }}>Paper Bag Manufacturing (Europe)</h2>
            <h4>Business Context:</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ul className="paperbag-ul" style={{ listStyleType: "square" }}>
                <li>Customer order size decreasing 40%</li>
                <li>Variety of bags designs required increasing</li>
                <li>Margins eroding</li>
                <li>Customers demanding 100% OTD</li>
                <li>
                  European regulations demanding all food related content MUST
                  be in paper bags
                </li>
                <li>
                  Current: its taking us 244 minutes to go from A type bags to a
                  different design A type bag
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Paperbag;
