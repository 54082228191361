import React, { Component } from "react";
import AimsCard from "../Card/AimsCard";
import $ from 'jquery'; 

import "./Services.scss";
// import servicesImage from "../../assets/images/services/services-background-2.jpg"
import servicesImage from "../../assets/images/services/pic3.jpg"

class Services extends Component {

  constructor(props){
    super(props);

    this.state = {
      servicesData:""
    }
  }

  getServicesDataData() {
    $.ajax({
      url: "./SiteData.json",
      dataType: "json",
      cache: false,
      success: function(data) {
        this.setState({ servicesData: data.services });
      }.bind(this),
      error: function(xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getServicesDataData();
  }

  render() {
    const mainList1 = this.state.servicesData;
    const mainList2 = this.state.servicesData;
    const servicesList1 = Object.keys(mainList1).slice(0,4);
    const servicesList2 = Object.keys(mainList2).slice(4,5);
    const servicesData = this.state.servicesData;

    return (
      <>
      {/* <img src={servicesImage}></img> */}
      <div className="services-header-image">
        <img src={servicesImage} />
        <h1 className="our-service-header">Our Services</h1>
      </div>
      <div className="aims-card-container">
        {
          servicesList1.map(serviceName => {
            return (
              <AimsCard key={serviceName} title={serviceName} servicesList={servicesData[`${serviceName}`]}/>
            )
          })
        }
      </div>
      <div className="aims-card-container-2">
        <AimsCard title={servicesList2[0]} servicesList={servicesData[`${servicesList2[0]}`]}/>
      </div>
      </>
    )
  }
}

export default Services;
