import React,{Component} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardHeader } from '@mui/material';
import "./aims-card.scss";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
  </Box>
);

class AimsCard extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }
  render(){

    return (
        <Card sx={{ minWidth: 275 }} className="aims-card">
          <CardContent>
          <Typography alignCenter align="center" gutterBottom variant="h3" component="div">
            {this.props.title}
          </Typography>
            <ul className="aims-cards-ul">
              {
                this.props.servicesList ? this.props.servicesList.map((service,index) => {
                  return (<li key={index} >{service}</li>)
                }) : ""
              }
            </ul>
          </CardContent>
        </Card>
    );
  }
}

export default AimsCard;