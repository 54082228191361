import React, { Component } from "react";

class Criteria extends Component {
  render() {
    return (
      <div
        id="criteria"
        style={{ padding: "30px 100px", backgroundColor: "white" }}
      >
        <div style={{ padding: "30px" }}>
          <section id="criteria">
            <h2 style={{ color: "black" }}>Criteria</h2>
            <div
              style={{
                marginLeft: "30px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ul className="criteria-ul" style={{ listStyleType: "square" }}>
                <li>
                  A comprehensive effort to achieve world-class manufacturing
                  capability.
                </li>
                <li>
                  Management practices geared to motivating achievement of
                  breakthroughs in operating performance and customer
                  satisfaction, as well as cultivating continuous improvement.
                </li>
                <li>
                  Extensive employee involvement and empowerment programs,
                  especially efforts to create and provide training for
                  high-performance work teams.
                </li>
                <li>
                  A strong customer focus, including formal
                  customer-satisfaction programs, customer involvement in
                  product design, employee contact with customers, and efforts
                  to reduce customer lead-times.
                </li>
                <li>
                  Improvements in manufacturing operations, including shortening
                  of manufacturing cycle times, improvements in productivity,
                  and reduction of inventories.
                </li>
                <li>Proactive environmental and safety practices.</li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Criteria;
