import React, { Component } from "react";
import $ from "jquery";

import contactPic from "../../assets/images/awards/contact.jpg";
import awardPic from "../../assets/images/awards/IBA-awards.png";
import aboutus from "../../assets/images/aboutus/aboutus.jpg";

import "./about-us.scss";

class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutUsData: [],
      awardsData: [],
    };
  }

  getAboutUsData() {
    $.ajax({
      url: "./SiteData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({
          aboutUsData: data.aboutus,
          awardsData: data.awards,
        });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getAboutUsData();
  }

  render() {
    var sclicedData = this.state.awardsData.slice(1, 5);
    return (
      <>
        <div className="aboutus-header-image">
          <img src={aboutus} />
          <h1 className="aboutus-header-label">About AIMS INC</h1>
        </div>
        <div className="aims-about-us">
          <div className="about-us-info">
            <img className="about-us-info-image" src={contactPic} />
            <h3>Vaidesh Subramanyam P.Eng CQE MBA(Ivey)</h3>
            <ul className="about-us-info-points">
              {this.state.aboutUsData.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </div>
          <div className="about-us-award">
            <img className="about-us-award-image" src={awardPic}></img>
            <h3>{this.state.awardsData[0]}</h3>
            <ul className="about-us-info-points">
              {sclicedData.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUs;
