import React, { Component } from "react";
import casestudpic from "../../assets/images/case-studies/casestudy.jpg";

import "./case-studies.scss";

class CaseStudies extends Component {
  render() {
    return (
      <>
        <div className="case-studies-header-image">
          <img src={casestudpic} />
          <h1 className="case-studies-header-label">Case Studies</h1>
        </div>
        <div
          id="recentaims"
          style={{ padding: "30px 100px", backgroundColor: "#3b5999" }}
        >
          <h2 style={{ color: "white" }}>
            Recent AIMS Inc. Cases in Healthcare
          </h2>
          <div style={{ padding: "30px" }}>
            <section id="recent-aims-inc">
              <div>
                <h2 style={{ color: "black" }}>Windsor Regional Hospital</h2>
                <h4 style={{ color: "#f3662c" }}>Operating Room Turnover</h4>
                <p style={{ color: "white" }}> Completed 2013 </p>
                <p style={{ color: "white" }}>
                  Emergency-related OR wait times averaged 1.8 days, causing bed
                  capacity to rise above 104%, significantly higher than their
                  85% benchmark. We met with staff and learned the desired state
                  to operd on medically-cleared patients the same day. We
                  assessed the situation and demonstrated the best way to
                  decrease ED wait times. We realized the operating rooms were
                  severely underutilized, operating on average only 48% of the
                  time. We showed th the actual procedure time took up less than
                  half the overall cycle time of the OR, while the time between
                  one patient leaving and the next patient entering took on
                  average 23 minutes. With utilization of Lean tools we were
                  able to make a significant reduction in changeover times
                  between patients. This greatly reduced the wait times for
                  emergency department surgery, while helping alleviate patient
                  stress and the hospital being over bed capacity.
                </p>
              </div>

              <div>
                <h2 style={{ color: "black" }}>Windsor Regional Hospital</h2>
                <h4 style={{ color: "#f3662c" }}>
                  Short Stay Medical Unit (SSM)
                </h4>
                <p style={{ color: "white" }}> Completed 2013 </p>
                <p style={{ color: "white" }}>
                  The hospital engaged us in a project to open a 20-bed Short
                  Stay Medical Unit. Once the core team was trained in Lean,
                  they set forth formed a service level agreement with the
                  physicians on the floor, having the guarantee that they will
                  see new patients within two hours.
                </p>

                <ul
                  title="Measurable Results"
                  style={{ listStyleType: "square", color: "black" }}
                >
                  <h4>Measurable Results</h4>
                  <li>
                    Proportional of all Family Medicine Patients released within
                    72 hours increased to 30% from 60%
                  </li>
                  <li>
                    Average transit time for short stay patients from emergency
                    department reduced from days to minutes
                  </li>
                </ul>

                <ul
                  title="Sustainability"
                  style={{ listStyleType: "square", color: "black" }}
                >
                  <h4>Sustainability</h4>
                  <li>
                    The core team was responsible for all improvement activities
                  </li>
                  <li>Leaders Standard Work</li>
                  <li>
                    We will monitor Average Patient Length of Stoy, Average
                    Daily Admission, Discharge and Occupancy, Average Transit
                    Time from ED, and Proportional of all Family Medicine
                    Patients Discharged within 72 hours on a weekly basis
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default CaseStudies;
